import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Connecticut Medical Malpractice Lawyer - Bartlett & Grippe",
    description:
      "Get help from experienced attorneys at Bartlett & Grippe for medical malpractice cases. We build the best possible case for you or your loved ones.",
    heroH1: "Connecticut Medical Malpractice",
    heroH2: "When experience, integrity, and results matter.",
    heroImage: "hero-medmal.jpg",
    heroImageAlt: "Photo of surgeons in an operating room",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Contact our experienced legal medical team today</h2>
            <h3>Talk to our medical malpractice attorney now.</h3>
            <p className="mb-8">
              Becoming injured due to medical malpractice is an unfortunate
              reality for many people. A May 2016 study from John Hopkins School
              of Medicine found medical errors may be responsible for more than
              a quarter of a million deaths annually, making medical errors the
              third leading cause of a non-violent death in the United States.
              This type of injury can be life-ending or cause long-term expense
              and heartache for loved ones.
            </p>
            <p className="mb-8">
              <strong>
                <em>
                  If you believe you or a loved one has been injured due to
                  medical malpractice, get help from an experienced medical
                  malpractice attorney at Bartlett & Grippe, LLC. Call 24 x 7
                  for a free consultation with no obligation.
                </em>
              </strong>
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                src="../../images/avvo10.png"
                formats={["auto", "webp", "avif"]}
                alt="Rated 10 for top litigation attorney for medical malpractice"
              />
            </div>
            <p className="mb-8">
              Our attorneys have helped Connecticut victims of medical
              malpractice obtain justice following unnecessary or irresponsible
              medical treatment. When medical care is not up to the standard of
              care, when there are mistakes, errors or misdiagnosed conditions,
              the patient can suffer great harm, including wrongful death.
              Attorneys at Bartlett & Grippe, LLC can help if you or a loved one
              has suffered harm due to poor medical treatment.
            </p>
            <p className="mb-8">
              Our Medical Malpractice Attorneys will review your case, look at
              all the evidence and find issues of concern that can be used in
              court or negotiations to support your claims. We work with our
              medical legal expert Bonnie Caines, medical consultants and
              physicians to establish whether the treatment you received fell
              below the standard of care and to establish the necessary causal
              connection between this negligent care and the injuries you
              received. Bonnie is a medical legal expert on staff to assist in
              interpreting the charts and records to help ensure you receive the
              compensation you deserve.
            </p>
            <p className="mb-8">
              We represent clients from all of Connecticut and help them gain
              fair compensation for injuries received due to substandard or poor
              medical care. We can help you if you or a family member have
              suffered from the following:
            </p>
            <ul className="list-disc list-outside ml-6">
              <li>Birth injuries</li>
              <li>Misdiagnoses / Failure to Diagnose</li>
              <li>Surgical Errors</li>
              <li>Prescription Errors / Interactions</li>
              <li>Death caused by a medical error</li>
            </ul>
          </div>
          <div className="mb-16">
            <h2>Why Choose Bartlett & Grippe?</h2>
            <p className="mb-8">
              Healthcare professionals will likely have defended against
              malpractice claims before; therefore, it is important for
              potential claimants to choose Bartlett & Grippe, attorneys who
              have experience litigating malpractice claims in Connecticut. Your
              lawsuit will be based not only on your evidence, testimony and
              witnesses, but also the claims, evidence, and expert witnesses
              presented by the opposing side in support of their defense. We
              will strive to anticipate the tactics employed by a healthcare
              professional to rebut or negate your claims.
            </p>
            <p className="mb-8">
              The attorneys in our Cheshire office are experienced in medical
              malpractice lawsuits and will use this experience to anticipate
              the strategies used by the defense. Our attorneys’ strengths are
              in our courtroom and negotiation tactics. We also have one of the
              best medical resources, Bonnie Caines on staff. The Bartlett &
              Grippe, LLC team will build the best possible case on your behalf.
            </p>
            <p className="mb-8">Do you have a Medical Malpractice case?</p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <p className="mb-8">
              If you or a loved one has been injured due to another’s
              negligence, our attorneys may be able to help you file a lawsuit
              to recover compensation for your losses. If you elect to take
              legal action, we will prepare your claim by:
            </p>
            <ul className="list-disc list-outside ml-6">
              <li>
                Reviewing information given to them to establish if you have a
                claim
              </li>
              <li>Collecting additional evidence to support your claim</li>
              <li>Determining the value of your claim</li>
              <li>Determining potentially liable parties</li>
              <li>
                Helping you understand your legal rights and options, moving
                forward
              </li>
              <li>Handling complicated technical rules and procedures</li>
            </ul>
            <p className="mb-8">
              While the evidence and facts may be on your side, the strength of
              your case may ultimately hinge on the ability of your attorney to
              prove a causal link. We understand how to package, present, and
              explain supporting (and adverse) evidence; question expert
              witnesses; rebut claims made by the defense through intelligent
              and thoughtful means, and turn neutral facts into favorable
              evidence or negate facts that are unfavorable to your case.
            </p>
          </div>
          <div className="mb-16">
            <h2>What Constitutes Medical Malpractice?</h2>
            <p className="mb-8">
              When a patient is injured due to the negligence of a medical
              professional, they may be able to file a lawsuit. The plaintiff
              must prove the following elements of proof to succeed in a medical
              malpractice lawsuit:
            </p>
            <ul className="list-disc list-outside ml-6">
              <li>
                <strong>Patient/Doctor Relationship:</strong> Individuals filing
                a medical malpractice lawsuit must be able to prove they had a
                physician-patient relationship with the doctor. The patient must
                hire the doctor and the doctor must have agreed to work for the
                patient. Medical practitioners can only be sued for acts of
                malpractice committed within the scope of employment.
              </li>
              <li>
                <strong>Duty of Care:</strong> Physicians are required to
                perform up to the accepted standard of the medical community.
                Doctors and other medical professionals have a legal obligation
                to their patients to act in the same manner that another
                practitioner in the same field and situation would.
              </li>
              <li>
                <strong>Breach of Duty:</strong> In order for a medical
                practitioner to have been negligent, they must have violated the
                duty of care to their patient. For the duty of care to have been
                broken, the doctor must have failed to act how another medical
                practitioner would have in a similar situation.
              </li>
              <li>
                <strong>Causation:</strong> In addition to negligence, patients
                must also prove causation to have a valid claim. Injured
                patients must prove that the medical practitioner’s breach of
                duty was the proximate cause of their injury. Through documents,
                further medical treatments, and affidavits from other healthcare
                providers, patients must be able to show a causal relationship
                between the doctor’s negligence and their injury.
              </li>
              <li>
                <strong>Damages:</strong> The injury caused by the healthcare
                provider’s negligence must have caused either economic or
                non-economic damages. This means that medical professionals
                cannot be held liable unless the injured patient incurred
                additional medical bills, lost wages or damage to their future
                earning capacity, or pain and suffering.
              </li>
            </ul>
          </div>
          <div className="mb-16">
            <h2>Medical Malpractice Damages</h2>
            <p className="mb-8">
              In medical malpractice cases, compensatory damages are awarded to
              compensate the injured patient for the harm caused by a medical
              professional’s negligence. Compensatory medical malpractice
              damages attempt to make the victim “whole” again and provide
              financial compensation for economic and non-economic losses. Also
              known as actual damages, economic losses that may be covered
              include:
            </p>
            <ul className="list-disc list-outside ml-6">
              <li>
                <strong>Medical expenses:</strong> These damages cover the cost
                of hospital stays, doctor visits, physical therapy, prescription
                drugs, assistive devices and similar expenses. When an injury is
                severe or permanent, the medical malpractice victim may also be
                awarded compensation for future medical expenses.
              </li>
              <li>
                <strong>Lost wages:</strong> Patients who must take time away
                from work to recover from their medical malpractice injuries may
                be able to collect compensation for lost wages.
              </li>
              <li>
                <strong>Loss of earning capacity:</strong> When a patient is
                unable to earn the same amount of money as he or she did prior
                to their accident, they may be awarded compensation for a loss
                of earning capacity.
              </li>
              <li>
                <strong>Pain and suffering:</strong> Medical malpractice damages
                for pain and suffering typically compensate the victim for the
                physical pain caused by their injuries. These damages may also
                include compensation for emotional distress, which refers to the
                anxiety, depression, fear, frustration and other mental
                sufferings that can develop as a result of a medical malpractice
                injury.
              </li>
              <li>
                <strong>Loss of Consortium:</strong> The spouse of the medical
                malpractice victim may be able to recover compensation for the
                loss of marital benefits. Loss of companionship, sexual
                relations, affection, and comfort are among the compensable
                marital benefits. Loss of consortium- medical malpractice
                damages is typically awarded in cases of life-changing or
                permanent injuries.
              </li>
              <li>
                <strong>Other Damages:</strong> There are other damages
                available in certain situations, including loss of life’s
                enjoyment, compensation for disability or disfigurement, loss of
                companionship, etc.
              </li>
            </ul>
            <p className="mb-8">
              If you or a loved one has suffered injury, pain, wrongful death,
              disability or other medical problems and care expense due to
              negligent medical treatment, get immediate legal representation
              and counsel. Our office is centrally located in Cheshire CT, and
              our attorneys are ready to meet with you to discuss your legal
              options. Contact us today for experienced legal representation;
              call us at (203) 463-4939.
            </p>
            <p className="mb-8">
              <Link to="/firm-overview/practice-areas/attorney-case-referral/">
                ATTORNEY MEDICAL MALPRACTICE CASE REFERRAL
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
